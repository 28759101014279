import React from 'react'

import Icon from '@/atoms/Icon'

import { StyledScrollDownButton } from './styles'

const ScrollDownButton = ({
  container,
  ...props
}) => {
  const onClick = () => {
    if (!container || !container.current) return
    const offsetToScroll = container.current.clientHeight
    window.scrollTo({ top: offsetToScroll, behavior: 'smooth' })
  }

  return (
    <StyledScrollDownButton
      aria-label='Esplora'
      onClick={onClick}
      {...props}
    >
      <Icon className="explore" name="explore" />
      <Icon className="arrowDown" name="arrow-down" />
    </StyledScrollDownButton>
  )
}

export default ScrollDownButton
