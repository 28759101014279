import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from 'react'
import Router from 'next/router'
import { usePathname, } from 'next/navigation'

import useIsMobile from 'hooks/useIsMobile'
import { GeneralContext } from 'contexts/General'

import {
  getFiltersFromQuerystringForAlgolia,
  normalizeDataForAlgoliaRequest
} from 'api/utils'

import { getFirstFocusableElement } from '@/utils'
import { Label } from '@/atoms/Typography'

import Form from '@/molecules/Form'
import LineDecorationButton from '@/molecules/Buttons/LineDecorationButton'

import {
  StyledCheckboxFilter,
  StyledCheckboxFilterMain,
  StyledCheckboxFilterExpand,
  StyledCheckboxFilterCta,
  StyledCheckboxFilterCtaCounter,
  StyledCheckboxFilterClose,
} from './styles'

const CheckboxFilterHeader = ({
  items,
  checkPrefill = false,
  async,
  cta = {},
  handlePayload = null,
  contentType,
  setResults = null,
  setTotalPages,
  setTotalItems,
}) => {
  const { brand } = useContext(GeneralContext)
  const [filterForm, setFilterForm] = useState(items)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedValues, setSelectedValues] = useState(0)
  const [isExpanded, setIsExpanded] = useState(false)

  const pathname = usePathname()
  const isMobile = useIsMobile('lg')
  const containerRef = useRef(null)

  const onClick = () => {
    setIsExpanded(status => !status)
  }

  const onMobileTrigger = () => {
    setIsOpen(v => !v)
  }

  const onFormChange = (values) => {
    const total = Object.entries(values).reduce((acc, [_, el]) => acc + el.length, 0)
    setSelectedValues(total)
  }

  const onFormSubmit = async (values) => {
    if (!async) return

    const prevQueryString = decodeURIComponent(window.location.search).replace('?', '')

    const filteredValues = Object.entries(values).filter(([_, itemsSelected]) => !!itemsSelected.length)
    const newQueryString = filteredValues.reduce((acc, [key, vals]) => {
      vals.forEach(val => {acc.push(`${key}=${val}`)})
      return acc
    }, []).join('&')

    if (prevQueryString === newQueryString) return

    const newUrl = `${pathname}?${newQueryString}`
    if (new URLSearchParams(window.location.search).get('s')) window.location.href = newUrl

    window.history.replaceState({
      ...window.history.state,
      as: newUrl,
      url: newUrl
    }, '', newUrl)

    const filters = normalizeDataForAlgoliaRequest(values, brand)

    if (!!filters) {
      const {
        nbPages,
        nbHits
      } = await handlePayload({
        additionalBodyData: { filters },
        page: 0,
        replace: true,
        type: contentType,
      })
      setTotalPages(nbPages)
      setTotalItems(nbHits)

      if (isMobile) {
        onMobileTrigger()
      }
    } else {
      setResults([])
      setTotalPages(1)
      setTotalItems(0)
    }
  }

  const iconsStart = useMemo(() => {
    return !isOpen && selectedValues === 0 ? 'branded-filters' : null
  }, [isOpen, selectedValues])

  const formProps = useMemo(() => {
    return isMobile
      ? {
        isExpanded: true,
        onFormChange,
        clear: false,
        submit: {
          small: true,
          general: true,
          type: 'submit',
          icon: null,
          label: 'Applica'
        },
        otherActions: [<StyledCheckboxFilterClose key={'close-mobile'}  small label="Chiudi" negative onClick={onMobileTrigger} />]
      }
      : {
        submit: {
          ...filterForm.submit,
          general: true,
        },
        isExpanded,
        onFieldsetClick: onClick
      }
  }, [isMobile, isExpanded])

  useEffect(() => {
    setIsOpen(false)
    if (!isMobile) setIsExpanded(false)
  }, [isMobile])


  useEffect(() => {
    if (isExpanded) getFirstFocusableElement(containerRef.current)
  }, [isExpanded])

  useEffect(() => {
    const getItems = async () => {
      if (!checkPrefill) {
        setFilterForm(items)
        return
      }

      const formCloned = structuredClone(items)
      const { form } = formCloned
      const { initialValues } = form
      for (const [key, value] of new URLSearchParams(window.location.search).entries()) {
        if (!initialValues[key] || initialValues[key].find((i) => i === value)) continue
        initialValues[key].push(value)
      }


      const filters = getFiltersFromQuerystringForAlgolia(brand)

      if (!filters && !new URLSearchParams(window.location.search).get('s')) {
        setFilterForm(formCloned)
        return
      }

      const {
        nbPages,
        nbHits
      } = await handlePayload({ additionalBodyData: { filters }, })
      setFilterForm(formCloned)
      setTotalPages(nbPages)
      setTotalItems(nbHits)
    }

    getItems()

    Router.events.on('routeChangeComplete', getItems)

    return () => {
      Router.events.off('routeChangeComplete', getItems)
    }
  }, [items])

  const title = 'Filtri di ricerca'

  return (
    <StyledCheckboxFilter>
      {
        isMobile &&
        <StyledCheckboxFilterCta big smooth negative iconStart={iconsStart} handleClick={onMobileTrigger} withValues={!!selectedValues}>
          {
            !isOpen &&
            !!selectedValues &&
            <StyledCheckboxFilterCtaCounter as="span"  bold>{selectedValues}</StyledCheckboxFilterCtaCounter>
          }
          {title}
        </StyledCheckboxFilterCta>
      }

      {
        <StyledCheckboxFilterMain
          ref={containerRef}
          isOpen={isOpen}
        >
          {
            isMobile &&
            <Label typo="heading2" as="h2" bold>{title}</Label>
          }

          <Form
            {...filterForm}
            {...formProps}
            onSubmit={onFormSubmit}
          />
        </StyledCheckboxFilterMain>
      }

      {
        !isMobile &&
        <StyledCheckboxFilterExpand>
          <LineDecorationButton
            onClick={onClick}
            isExpanded={isExpanded}
            cta={{
              icon: 'chevron-down',
              label: !isExpanded ? cta.open : cta.close
            }}
          />
        </StyledCheckboxFilterExpand>
      }
    </StyledCheckboxFilter>
  )
}

export default CheckboxFilterHeader
