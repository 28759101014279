import React, { useMemo } from 'react'

import useIsMobile from 'hooks/useIsMobile'

import DropDown from '@/atoms/Dropdown'
import Button from '@/atoms/Button'

import {
  StyledLineFilterWrapper,
  StyledLineFilterList,
  StyledLineFilterListMobile,
  StyledLineFilterListMobileWrapper,
  StyledLineFilterListContainer,
  StyledLineFilter,
} from './styles'

const LineFilter = ({
  items = [],
  type,
  actions,
}) => {
  const isMobile = useIsMobile()
  const currentFilterSelected = useMemo(() => items ? items.find(item => item.current) : {}, [items])

  return (
    items &&
    !!items.length &&
    <StyledLineFilterWrapper>
      <StyledLineFilterList>
        {
          isMobile ?
            <DropDown
              trigger={{
                label: currentFilterSelected.title,
                iconEnd: 'chevron-down'
              }}
            >
              <StyledLineFilterListMobileWrapper>
                <StyledLineFilterListMobile>
                  {
                    items.map((props) =>{
                      const {
                        href,
                        title,
                        current
                      } = props

                      return (
                        <Button
                          className={current ? 'current' : false}
                          reset
                          key={title}

                          href={href}
                          typo="buttonRegular"
                          handleClick={actions && actions.onClick ? () => actions.onClick(props) : false }
                        >{title}</Button>
                      )
                    })
                  }
                </StyledLineFilterListMobile>
              </StyledLineFilterListMobileWrapper>
            </DropDown>
            : <StyledLineFilterListContainer gap={16} type={type}>
              {
                items.map((props) =>{
                  const {
                    href,
                    title,
                    current
                  } = props
                  return (
                    <StyledLineFilter
                      key={title}
                      current={current}
                      href={href}
                      negative small bold
                      typo="buttonRegular"
                      handleClick={actions && actions.onClick ? () => actions.onClick(props) : false }
                    >{title}</StyledLineFilter>
                  )
                })
              }
            </StyledLineFilterListContainer>
        }

      </StyledLineFilterList>
    </StyledLineFilterWrapper>
  )
}

export default LineFilter
