import React from 'react'
import { useRouter } from 'next/router'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

import Metadata from '@/organisms/Metadata'
import Header from '@/organisms/Header'
import Footer from '@/organisms/Footer'


const DefaultLayout = ({
  metadata,
  children
}) => {
  const router = useRouter()

  return (
    <>
      <Metadata {...metadata} />
      <Header key={router.asPath} />
      {children}
      <Footer key="footer" />
    </>
  )
}

export default DefaultLayout
