import React from 'react'

import { getFirstFocusableElement } from '@/utils'
import { StyledSkipToContent, StyledSkipToContentTrigger } from './styles'

const SkipToContent = () => {
  const handleScrollTo = () => {
    const element = document.getElementById('main-content')

    if (!!element) {
      const y = element.getBoundingClientRect().top

      window.scrollTo({ top: y, behavior: 'smooth' })

      getFirstFocusableElement(element)
    }
  }

  return (
    <StyledSkipToContent>
      <StyledSkipToContentTrigger onClick={handleScrollTo}>
        Vai al contenuto principale
      </StyledSkipToContentTrigger>
    </StyledSkipToContent>
  )
}


export default SkipToContent
