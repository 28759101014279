import React from 'react'

import Icon from '@/atoms/Icon'

import { StyledBackToTopButton } from './styles'

const BackToTopButton = () => {
  const onClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <StyledBackToTopButton
      aria-label='Torna su'
      onClick={onClick}
    >
      <Icon name="arrow-up" />
    </StyledBackToTopButton>
  )
}

export default BackToTopButton
